import { P } from 'app/components/common/Typography';
import React from 'react';
import ImageBottle from 'images/Supplement_Bottle.png';
import ImageSupplementFacts from 'images/supplement_v3_facts.png';
import ImageSupplementFactsMobile from 'images/supplement_v3_facts_mobile.png';

const ModalBodySupplementInfo = () => (
  <div className="flex">
    <div>
      <img src={ImageBottle} alt="" />
    </div>
    <div>
      <h3>Covering All Your Bases:</h3>
      <h1 className="mt12 bold" style={{ fontSize: '26px' }}>
        Maximus Building Blocks
      </h1>
      <P className="mb24 mt24">
        <img className="bottle_mobile" src={ImageBottle} />
        Maximus has the best multivitamin on the market, providing the 'Building Blocks' for your body's hormone and
        energy production. Other multivitamins use poorly absorbed forms and dosages of key fat-soluble Vitamins (A, D,
        E, K) that will never get you into optimal ranges. Building Blocks uniquely includes a bioavailable B-complex,
        patented Albion® trace minerals (copper, manganese, and molybdenum), and iodine to support optimal thyroid
        function.
        <br />
        <br />
        Note that the recommended daily values (%DV) on supplement labels are just the bare minimum, not what's optimal
        for health. Building Blocks was designed based on extensive medical research and clinical experience to create a
        prescription-strength formula that makes a meaningful difference in how you feel and perform.
      </P>
      <img
        sizes="(max-width: 456px) 350px, 650px"
        className="mt24 info_table"
        srcSet={`${ImageSupplementFactsMobile} 350w, ${ImageSupplementFacts} 650w`}
        src={ImageSupplementFactsMobile}
      />
    </div>
  </div>
);

export default ModalBodySupplementInfo;
