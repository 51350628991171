import React, { Fragment } from 'react';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import PageHeader from 'app/components/common/PageHeader';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';
import { MultimonthConfig } from 'app/components/customer/steps/Payment/king/KingMultimonthPlanSelectionForm';
import { AvailableProducts } from 'app/constants/Products';
import DrugsBottle from 'images/sub-products/king/king-drugs.png';
import { KING_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';

const StepMultimonthPlanSelection = ({ initialValues, onSubmit }) => {
  const handleChangeProduct = () => {
    return;
  };

  const description = (
    <Fragment>
      <p className="card-description__title">NOTE: TRT products are not available in your state at this time.</p>
      <br />
      Enclomiphene is our best solution for improving Testosterone in the meantime. Choose the best plan for you below.
    </Fragment>
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <div className="centered mb24 updated-design payments">
          <PageHeader title="Choose Your Plan" className="treatment_plan__header mb24 -edged" />
          <div className="content">
            <GenericMultimonthPlanSelectionForm
              productName={AvailableProducts.King}
              selectedSubProduct={initialValues.selected_king_v2_product}
              multimonthConfig={MultimonthConfig}
              handleChangeProduct={handleChangeProduct}
              showBreadcrumbs={false}
              drugsImage={DrugsBottle}
              priceConfig={KING_MONTHLY_PRICE}
              description={description}
            />
            <BackNextFooter
              noBack={true}
              onNext={handleSubmit}
              testId="submit-plan-selection"
              horizontalPadding={false}
              nextDisabled={!values.multimonth_plan}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default StepMultimonthPlanSelection;
