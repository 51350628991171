import React from 'react';

import '../css/Payment.scss';
import GenericSubscriptionDetails from 'app/components/customer/steps/Payment/Generic/GenericSubscriptionDetails';
import GenericBuildingBlocks from 'app/components/customer/steps/Payment/Generic/GenericBuildingBlocks';
import GenericOnboardingServices from 'app/components/customer/steps/Payment/Generic/GenericOnboardingServices';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import Select from 'react-select';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import '../css/GenericPaymentPage.scss';
import { useFormikContext } from 'formik';
import PriceCalculator from 'app/helpers/priceCalculator';
import { Product, CheckoutHelper } from 'app/types/admin/customerUser';
import { AvailableProducts } from 'app/constants/Products';
import { ImmutableMap } from 'app/types/admin';

type Props = {
  currentProductName: AvailableProducts;
  multimonthPeriod: string;
  multimonthPlan: number;
  subProductName: string;
  multimonthEnabled: boolean;
  checkoutHelper: CheckoutHelper;
  isOnboarding: boolean;
  supplementsOnAnotherIntake: boolean;
  activeProductWithSupplementName: string | undefined;
  productChanged: boolean;
  product: ImmutableMap<Product>;
  priceCalculator: PriceCalculator;
  goToSelectPlanPage?: (e: any) => void;
  goToSelectProductPage?: (e: any) => void;
  goToSelectLabPage?: (e: any) => void;
  onMultimonthPlanChange?: (e: any) => void;
};

const SubscriptionWidget = ({
  currentProductName,
  product,
  priceCalculator,
  goToSelectProductPage,
  goToSelectLabPage,
  multimonthPeriod,
  multimonthPlan,
  isOnboarding,
  supplementsOnAnotherIntake,
  activeProductWithSupplementName,
  multimonthEnabled,
  checkoutHelper,
  onMultimonthPlanChange,
  productChanged,
  subProductName,
}: Props) => {
  const { setFieldValue } = useFormikContext<{
    multimonth_plan: number;
  }>();

  const onMultimonthUpdate = (value) => {
    setFieldValue('multimonth_plan', value);
    if (onMultimonthPlanChange) {
      onMultimonthPlanChange(value);
    }
  };

  const SubscriptionSummaryComponent = checkoutHelper.subscriptionSummaryComponent();

  return (
    <>
      {checkoutHelper.multimonthEnabled() && (
        <div style={{ position: 'relative' }}>
          <TitleWithLine className="flex">
            <div>Subscription</div>
          </TitleWithLine>
          <Select
            className="multimonth-dropdown"
            options={checkoutHelper.multimonthPlansOptions(subProductName)}
            value={checkoutHelper.multimonthPlansOptions(subProductName).find((o) => o.value == multimonthPlan)}
            onChange={(o) => {
              onMultimonthUpdate(o?.value);
            }}
          />
        </div>
      )}
      <PaymentCard className="mb12">
        <GenericSubscriptionDetails
          currentProductName={currentProductName}
          product={product}
          priceCalculator={priceCalculator}
          multimonthEnabled={checkoutHelper.multimonthEnabled()}
          multimonthPeriod={multimonthPeriod}
          handleChangeProduct={goToSelectProductPage}
          handleChangeLab={goToSelectLabPage}
          subProductName={subProductName}
          checkoutHelper={checkoutHelper}
        />
        <GenericBuildingBlocks
          currentProductName={currentProductName}
          supplementsOnAnotherIntake={supplementsOnAnotherIntake}
          multimonthEnabled={multimonthEnabled}
          multimonthPlan={multimonthPlan}
          priceCalculator={priceCalculator}
          bbOutOfStock={checkoutHelper.bbOutOfStock()}
          activeProductWithSupplementName={activeProductWithSupplementName}
          isOnboarding={isOnboarding}
        />
        {isOnboarding && <GenericOnboardingServices />}
        <Divider className="mt32" />
        <SubscriptionSummaryComponent
          multimonthPeriod={multimonthPeriod}
          currentProductName={currentProductName}
          multimonthPlan={multimonthPlan}
          priceCalculator={priceCalculator}
          isOnboarding={isOnboarding}
          productChanged={productChanged}
          nextBillingCycle={nextBillingCycle(currentProductName)}
        />
      </PaymentCard>
    </>
  );
};

export default SubscriptionWidget;
