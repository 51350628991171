/* eslint-disable no-case-declarations */
import { LabKitTypes, KingV2SubProducts } from 'app/constants/Products';
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

const BB_PRICING = {
  1: 49_99,
  3: 44_99,
  12: 34_99,
};

export const KING_MONTHLY_PRICE = {
  [KingV2SubProducts.Enclo]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.EncloPregnolone]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.OralTrt]: {
    1: 249_99,
    3: 199_99,
    12: 149_99,
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
  [KingV2SubProducts.EncloTrt]: {
    1: 299_99,
    3: 249_99,
    12: 199_99,
  },
  [KingV2SubProducts.Topical]: {
    1: 209_99,
    3: 159_99,
    12: 109_99,
  },
  [KingV2SubProducts.EncloTopical]: {
    1: 289_99,
    3: 239_99,
    12: 189_99,
  },
  [KingV2SubProducts.Injectable]: {
    1: 199_99,
    3: 149_99,
    12: 99_99,
  },
  [KingV2SubProducts.EncloInjectable]: {
    1: 279_99,
    3: 229_99,
    12: 179_99,
  },
  [KingV2SubProducts.InjectableHCG]: {
    3: 349_99,
    12: 299_99,
  },
};

const LAB_PRICE_BY_INTAKE_NUMBER = {
  1: 149_99,
  2: 99_99,
  3: 149_99,
};

class KingPriceCalculator extends BasePriceCalculator {
  selected_king_v2_product: KingV2SubProducts;
  isOnboarding: boolean;
  oralTrtLabNumber?: 1 | 2 | 3 | null;
  crlFeatureEnabledEncloPregWeightLoss: boolean;
  labKitType: LabKitTypes;
  productChangedtoOralTrt: boolean;

  KING_LAB_COST = 99_99;
  KING_WALK_IN_LAB_COST = 109_99;
  LEGACY_KING_LAB_COST = 99_99;
  APPOINTMENT_PRICE = 20_00;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    selected_king_v2_product: KingV2SubProducts,
    multiMonthPlan: 1 | 3 | 12,
    useOwnLab: boolean,
    intakeName: string,
    oral_trt_lab_number: null | 1 | 2 | 3 = null,
    crlFeatureEnabledEncloPregWeightLoss = false,
    labKitType: LabKitTypes = LabKitTypes.AtHomeLabKit,
    isOnboarding = false,
    subscriptionPaid = false,
    labPaid = false,
    productChangedtoOralTrt = false,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName, multiMonthPlan, useOwnLab, subscriptionPaid, labPaid);

    // This is specifically for when an Admin updates the User's
    //  Enclomiphene Version to Enclo Only before they check out
    if (selected_king_v2_product === KingV2SubProducts.Enclo) {
      this.selected_king_v2_product = KingV2SubProducts.EncloPregnolone;
    } else {
      this.selected_king_v2_product = selected_king_v2_product;
    }

    this.labKitType = labKitType;
    this.oralTrtLabNumber = oral_trt_lab_number;
    this.crlFeatureEnabledEncloPregWeightLoss = crlFeatureEnabledEncloPregWeightLoss;
    this.isOnboarding = isOnboarding;
    this.productChangedtoOralTrt = productChangedtoOralTrt;
  }

  // Interface
  monthlyCost() {
    return KING_MONTHLY_PRICE[this.selected_king_v2_product][this.multiMonthPlan];
  }

  fullMonthlyCost() {
    return this.monthlyCost()! + (this.includeBB ? this.bbPrice() : 0);
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    if (!this.multiMonthPlan) {
      return 199_00;
    }

    const bbMonthlyPrice = this.includeBB ? BB_PRICING[this.multiMonthPlan] : 0;

    return (
      (KING_MONTHLY_PRICE[this.selected_king_v2_product][this.multiMonthPlan] + bbMonthlyPrice) * this.multiMonthPlan
    );
  }

  appointmentCost() {
    return this.oralTrt() && (this.isOnboarding || this.productChangedtoOralTrt) ? this.APPOINTMENT_PRICE : 0;
  }

  atHomeLabPrice() {
    if (this.oralTrt()) {
      const monthNumber = this.oralTrtLabNumber || 1;
      return LAB_PRICE_BY_INTAKE_NUMBER[monthNumber];
    } else if (this.crlFeatureEnabledEncloPregWeightLoss) {
      return this.KING_LAB_COST;
    } else {
      return this.LEGACY_KING_LAB_COST;
    }
  }

  labCost() {
    if (this.labPaid || this.useOwnLab || (!this.isOnboarding && !this.productChangedtoOralTrt)) return 0;

    return this.labKitType === LabKitTypes.WalkIn ? this.KING_WALK_IN_LAB_COST : this.atHomeLabPrice();
  }

  totalDueTodayForCurrentProduct = () => this.subscriptionCost() + this.labCost() + this.appointmentCost();

  // Discounts
  bbDiscount() {
    if (!(this.includeBB || this.includeBBonAnotherProtocol) || !this.multiMonthPlan || this.multiMonthPlan < 3) {
      return 0;
    }

    return this.bbPotentialDiscount();
  }

  bbPotentialDiscount = () => (this.BB_PRICE - this.bbPrice() > 0 ? this.BB_PRICE : 0);

  nonDiscountedPrice = () => 0;

  // Private product specific methods
  oralTrt() {
    const trtProducts = [
      KingV2SubProducts.OralTrt,
      KingV2SubProducts.EncloPregnoloneTrt,
      KingV2SubProducts.EncloTrt,
      KingV2SubProducts.Injectable,
      KingV2SubProducts.EncloInjectable,
      KingV2SubProducts.InjectableHCG,
      KingV2SubProducts.Topical,
      KingV2SubProducts.EncloTopical,
    ];

    return trtProducts.includes(this.selected_king_v2_product);
  }

  bbPrice = () => {
    return BB_PRICING[this.multiMonthPlan];
  };

  montlyPayment = () => {
    return KING_MONTHLY_PRICE[this.selected_king_v2_product][1];
  };

  quarterlyPayment = () => {
    return KING_MONTHLY_PRICE[this.selected_king_v2_product][3] * 3;
  };

  yearlyPayment = () => {
    return KING_MONTHLY_PRICE[this.selected_king_v2_product][12] * 12;
  };
}

export default KingPriceCalculator;
